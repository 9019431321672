<template>
<!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
<div id="be38f9ef">
  <div class="dal-header">
    <el-button id="e4dbef26" @click="clicke4dbef26()">
      恭贺武汉数字智能科技有限公司越做越好！
    </el-button>
    <el-button id="a171926b" @click="clicka171926b()">
      系统设置
    </el-button>
    <el-button id="adccd45a" @click="clickadccd45a()">
      消息通知
    </el-button>
    <el-button id="a72523ee" @click="clicka72523ee()">
      木已成舟
    </el-button>
  </div>
  <div class="dal-rtv">
    <router-view>
    </router-view>
  </div>
</div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
export default{
  data(){
    return{
      //在此注释下方添加自定义变量
    }
  },
  mounted(){
  },
  computed:{
  },
  watch:{
  },
  methods:{
    async clicke4dbef26(){
      this.$router.push('question')
    },
    async clicka171926b(){
      this.$router.push('question')
    },
    async clickadccd45a(){
      this.$router.push('question')
    },
    async clicka72523ee(){
      this.$router.push('question')
    }
    //在此注释下方添加自定义函数或事件
  }
}
</script>
<style>
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#be38f9ef .dal-header{
  height:156px;
  background-color:#fff;
}
#be38f9ef .dal-rtv{
  flex: 1;
  position: relative;
}
#e4dbef26{
  width:550px;
  margin-left:50px;
  margin-top:53px;
  margin-bottom:51px;
  display:inline-block;
  vertical-align: middle;
}
#e4dbef26{
  background-color:rgb(255,255,255,0.009452530182898045);
  border-radius:0px 0px 0px 0px;
}
#e4dbef26{
  color:#6f7484;
  font-size:25px;
  font-family:PingFang SC;
  border:transparent;
}
#a171926b{
  width:173px;
  margin-left:342px;
  margin-top:56px;
  margin-bottom:55px;
  display:inline-block;
  vertical-align: middle;
}
#a171926b{
  background-color:rgb(255,255,255,0.009392890147864819);
  border-radius:0px 0px 0px 0px;
}
#a171926b{
  color:#6f7484;
  font-size:25px;
  font-family:PingFang SC;
  border:transparent;
}
#adccd45a{
  width:165px;
  margin-left:40px;
  margin-top:58px;
  margin-bottom:56px;
  display:inline-block;
  vertical-align: middle;
}
#adccd45a{
  background-color:rgb(255,255,255,0.01243439968675375);
  border-radius:0px 0px 0px 0px;
}
#adccd45a{
  color:#6f7484;
  font-size:25px;
  font-family:PingFang SC;
  border:transparent;
}
#a72523ee{
  width:192px;
  margin-left:78px;
  margin-top:48px;
  margin-bottom:46px;
  display:inline-block;
  vertical-align: middle;
}
#a72523ee{
  background-color:rgb(255,255,255,0.010615499690175056);
  border-radius:0px 0px 0px 0px;
}
#a72523ee{
  color:#141620;
  font-size:25px;
  font-family:PingFang SC;
  border:transparent;
}
/*在此注释下方添加自定义样式*/
</style>
